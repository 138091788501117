(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("AddYieldDigController", AddYieldDigController);

    AddYieldDigController.$inject = ["$scope", "$rootScope", "$route", "$filter", "$mdDialog", "YieldDigFormConstants",
        "PlotSummaryResource", "PotatoYieldDigAssessmentResults", "YieldDigParentForm", "StandardGradingHelpers",
        "CustomGradingHelpers", "yieldDigGradingTemplate", "TemplateGradingReplicateHelpers", "$khMessage",
        "yieldDigSlug", "yieldDigResult", "SelectedTeamService", "GenusFactory"];

    function AddYieldDigController($scope, $rootScope, $route, $filter, $mdDialog, YieldDigFormConstants,
                                   PlotSummaryResource, PotatoYieldDigAssessmentResults, YieldDigParentForm, StandardGradingHelpers,
                                   CustomGradingHelpers, yieldDigGradingTemplate, TemplateGradingReplicateHelpers, $khMessage,
                                   yieldDigSlug, yieldDigResult, SelectedTeamService, GenusFactory) {

        getFormConstants();
        const STANDARD = "STANDARD";
        const CUSTOM = "CUSTOM";
        const TEMPLATE = "TEMPLATE";
        if (yieldDigResult) {
            $scope.formTitle = "Edit Yield Dig Results";
            $scope.yield_dig_assessment = generateFormDataFromApi(yieldDigResult);
        } else {
            $scope.formTitle = "Add Yield Dig Results";
            $scope.yield_dig_assessment = {
                replicates: []
            };
        }
        $scope.saveYieldDig = saveYieldDig;

        $scope.removeYieldReplicate = function () {
        };

        $scope.checkReplicateSetup = function () {
            if ($scope.yield_dig_assessment.profile_type === STANDARD) {
                return StandardGradingHelpers.checkStandardReplicateSetup($scope.yield_dig_assessment);
            } else if ($scope.yield_dig_assessment.profile_type === CUSTOM) {
                return false;
            } else if ($scope.yield_dig_assessment.profile_type === TEMPLATE && $scope.yield_dig_assessment.template) {
                return false;
            } else {
                return true;
            }
        };

        $scope.addYieldReplicate = function () {
            if ($scope.yield_dig_assessment.profile_type === STANDARD) {
                $mdDialog.show({
                    controller: "AddYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/standard_grading/AddStandardYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        replicate_form: generate_replicate_form()
                    }
                }).then(function () {
                    $route.reload();
                });
            } else if ($scope.yield_dig_assessment.profile_type === CUSTOM) {
                $mdDialog.show({
                    controller: "AddCustomYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/custom_grading/AddCustomYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {}
                }).then(function () {
                    $route.reload();
                });
            } else if ($scope.yield_dig_assessment.profile_type === TEMPLATE) {
                $mdDialog.show({
                    controller: "AddYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/standard_grading/AddStandardYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        replicate_form: generate_replicate_form()
                    }
                }).then(function () {
                    $route.reload();
                });
            }
        };
        $scope.editYieldReplicate = function (index) {
            if ($scope.yield_dig_assessment.profile_type === STANDARD) {
                $mdDialog.show({
                    controller: "EditStandardYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/standard_grading/EditStandardYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plants: $scope.yield_dig_assessment.replicates[index].plants,
                        stems: $scope.yield_dig_assessment.replicates[index].stems,
                        dry_matter: $scope.yield_dig_assessment.replicates[index].dry_matter,
                        index: index,
                        replicate_form: edit_replicate_form(index)
                    }
                }).then(function () {
                    //add a record of the replicate on screen
                });
            } else if ($scope.yield_dig_assessment.profile_type === CUSTOM) {
                $mdDialog.show({
                    controller: "EditCustomYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/custom_grading/EditCustomYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plants: $scope.yield_dig_assessment.replicates[index].plants,
                        stems: $scope.yield_dig_assessment.replicates[index].stems,
                        dry_matter: $scope.yield_dig_assessment.replicates[index].dry_matter,
                        index: index,
                        replicate_form: edit_replicate_form(index)
                    }
                }).then(function () {
                    //add a record of the replicate on screen
                });
            } else if ($scope.yield_dig_assessment.profile_type === TEMPLATE) {
                $mdDialog.show({
                    controller: "EditStandardYieldReplicateController",
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/standard_grading/EditStandardYieldReplicate.html",
                    skipHide: true,
                    multiple: true,
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plants: $scope.yield_dig_assessment.replicates[index].plants,
                        stems: $scope.yield_dig_assessment.replicates[index].stems,
                        dry_matter: $scope.yield_dig_assessment.replicates[index].dry_matter,
                        index: index,
                        replicate_form: edit_replicate_form(index)
                    }
                }).then(function () {
                    //add a record of the replicate on screen
                });
            }
        };

        function getFormConstants() {
            GenusFactory.query(
                {"crop_name": 'Potato'},
                function(response){
                    let genus_id = response[0].id;
                    let team_id = SelectedTeamService.getSelectedId();
                    YieldDigFormConstants.query(function (result) {
                        $scope.dig_lengths = result.dig_lengths;
                        $scope.starting_sizes = result.starting_sizes;
                        $scope.ending_sizes = result.ending_sizes;
                        $scope.increment_sizes = result.increment_sizes;
                        getFieldPlotCrops(function () {
                            setupForm();
                        }, genus_id, team_id);
                    });
                    yieldDigGradingTemplate.query({}, function (success) {
                        $scope.replicateTemplates = success;
                    }, function (error) {
                        $khMessage.show("Unable to load yield dig templates, please refresh and try again", 4000);
                    });
                }
            );
        }

        function getFieldPlotCrops(callback, genus_id, team_id) {
            PlotSummaryResource.query({
                    "growing_season": $rootScope.selected_growing_season.value,
                    "team_id": team_id,
                    "genus_id": genus_id
                },
                function(response){
                    var dataItems = response;
                    var potato_crops = [];
                    angular.forEach(dataItems, function (item) {
                        let plot_name = item.farm_name + ' - ' + item.field_name + ' - ' + item.name + ' - ' + item.variety_name;
                        potato_crops.push({value: item.slug, name: plot_name});
                    });
                    $scope.field_plot_crops = potato_crops;
                    callback();
                }
            );
        }

        function setupForm() {
            $scope.yield_dig_assessment_form = YieldDigParentForm.fetch($scope);
        }

        function generate_replicate_form() {
            if ($scope.yield_dig_assessment.profile_type === STANDARD) {
                return StandardGradingHelpers.standardReplicateForm($scope);
            }
            if ($scope.yield_dig_assessment.profile_type === CUSTOM) {
                return CustomGradingHelpers.customReplicateForm($scope);
            }
            if ($scope.yield_dig_assessment.profile_type === TEMPLATE) {
                return TemplateGradingReplicateHelpers.templateReplicateForm($scope);
            }
            return [];
        }

        function edit_replicate_form(index) {
            if ($scope.yield_dig_assessment.profile_type === STANDARD) {
                return StandardGradingHelpers.editStandardReplicateForm($scope, index);
            }
            if ($scope.yield_dig_assessment.profile_type === CUSTOM) {
                return CustomGradingHelpers.editCustomReplicateForm($scope, index);
            }
            if ($scope.yield_dig_assessment.profile_type === TEMPLATE) {
                return TemplateGradingReplicateHelpers.editTemplateReplicateForm($scope, index);
            }
        }

        function saveYieldDig() {
            var dataForSubmission = angular.copy($scope.yield_dig_assessment);
            dataForSubmission = generateApiData(dataForSubmission);

            if (yieldDigSlug) {
                PotatoYieldDigAssessmentResults.update({yield_dig_slug: yieldDigSlug}, dataForSubmission, function (success_data) {
                    $khMessage.show("Updated successfully", 5000);
                    $scope.hideModal();
                    $route.reload();
                }, function (fail_data) {
                    $khMessage.show("Failed to update", 5000);
                });
            } else {
                PotatoYieldDigAssessmentResults.save(dataForSubmission, function (success_data) {
                    $khMessage.show("Created successfully", 5000);
                    $scope.hideModal();
                    $route.reload();
                }, function (fail_data) {
                    $khMessage.show("Failed to create", 5000);
                });
            }
        }

        function generateApiData(data) {
            var restructuredReplicates = [];
            if (data.profile_type === STANDARD) {
                angular.forEach(data.replicates, function (replicate) {
                    restructuredReplicates.push(StandardGradingHelpers.restructureStandardReplicate(replicate, data, $scope));
                });
            } else if (data.profile_type === CUSTOM) {
                angular.forEach(data.replicates, function (replicate) {
                    restructuredReplicates.push(CustomGradingHelpers.restructureCustomReplicate(replicate, data));
                });
            } else if (data.profile_type === TEMPLATE) {
                angular.forEach(data.replicates, function (replicate) {
                    restructuredReplicates.push(TemplateGradingReplicateHelpers.restructureTemplateReplicate(replicate, data, $scope));
                });
            }
            data.replicates = restructuredReplicates;
            return data;
        }

        function generateFormDataFromApi(apiData) {
            var data = angular.copy(apiData);

            data.sample_date = new Date(apiData.sample_date);
            data.dig_length = data.replicates[0]["dig_length"]["value"];
            data.rows_sampled = data.replicates[0]["rows_sampled"];
            data.profile_type = CUSTOM;

            angular.forEach(data.replicates, function (replicate, idx, obj) {
                angular.forEach(replicate.grade_profile, function (partition, gradeIdx, partitionObj) {
                    data.replicates[idx].grade_profile[gradeIdx] = {
                        partition_lower_bound: partition.grade_size.lower_bound,
                        partition_upper_bound: partition.grade_size.upper_bound,
                        partition_count: partition.count,
                        partition_weight: partition.weight,
                    };
                });
                replicate.grade_profile[replicate.grade_profile.length - 1].is_final_partition = true;
            });
            return data;
        }

        $scope.hideModal = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    }

})(angular);
