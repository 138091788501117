angular
  .module('OilSeedRapeGrowthApp')
  .controller('OSRGrowthMapController', function(
    $scope,
    $rootScope,
    $khMessage,
    $mdDialog,
    $routeParams,
    NgTableParams,
    $khPulse,
    leafletData,
    OilSeedRapeGrowthFactory,
    OsrGrowthFarmListFactory,
    OsrGrowthFarmRecordFactory,
    TeamsOfUserFactory,
    OilSeedRapeGrowthConstantsFactory,
    OilSeedRapeGrowthAnalyticsFactory,
    PlatformSettingsFactory,
    GenericMapService,
    CrowdsourceMapSpeedDialOptions
  ) {
    $rootScope.pageTitle = 'Crowdsource';
    $rootScope.page_id = 'crowdsource';
    $rootScope.sub_app_page_id = 'crowdsource_osr_scelerotinia';
    var mapid = 'landingMap';

    var layer;
    layer = GenericMapService.buildmap(
      $scope,
      $rootScope,
      leafletData,
      mapid,
      CrowdsourceMapSpeedDialOptions
    );

    PlatformSettingsFactory.get(function(data) {
      $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
      $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
      $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
      $scope.default_latval = data.DEFAULT_CENTER_LAT;
      $scope.default_lngval = data.DEFAULT_CENTER_LONG;
      $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
      if (typeof $rootScope.selected_growing_season === 'undefined') {
        $rootScope.selected_growing_season = {};
        $rootScope.selected_growing_season.name =
          $scope.planting_season_current;
        $rootScope.selected_growing_season.value =
          $scope.planting_season_current;
        $scope.$broadcast('filter:selected_growing_season');
      }
      setUpMonitorData();
    });

    $scope.dataAnalysisCards = [
      {
        card_title: 'Response Analytics',
        kh_card_content_template:
          '/static/assets/khapps/crowdsource/dashboard/partials/includes/AnalyticsStatsTable.html',
        //kh_card_toolbar_filters_template: '/static/assets/khapps/fms_app/farm/partials/fms_table_nav_links.html',
        card_flex: 100
      }
    ];

    $rootScope.$on('changedselectedFeature', function() {
      GenericMapService.changed_selection($rootScope, $scope);
    });

    function setUpMonitorData() {
      for (var i = 0; i < monitors.length; i++) {
        if (monitors[i].short_name == 'Sclerotinia') {
          $scope.monitor_crop = monitors[i].crop_name;
          $scope.monitor_description = monitors[i].description;
          $scope.monitor_interpretation = monitors[i].interpretation;
          $scope.monitor_information_status = true;
          $rootScope.monitor_name = monitors[i].name;
        }
      }
      //$khPulse.show();
      $scope.toggleSideBar();
    }

    $scope.$on('filter:selections_updated', function(obj, filterValue) {
      if (filterValue.hasOwnProperty('growing_season')) {
        getCrowdsourceAppStats($scope, OilSeedRapeGrowthAnalyticsFactory);
        if (typeof $rootScope.users_responses_geoJsonLayer == 'undefined') {
          GenericMapService.loadCSResponseData(
            $khPulse,
            $rootScope,
            leafletData,
            mapid,
            OilSeedRapeGrowthFactory
          );
        } else {
          GenericMapService.load_CSResponsedata(
            OilSeedRapeGrowthFactory,
            $khPulse,
            $rootScope,
            leafletData,
            mapid
          );
        }
      }
    });

    function getCrowdsourceAppStats($scope, OilSeedRapeGrowthAnalyticsFactory) {
      $scope.crowdsourceApps = [];
      OilSeedRapeGrowthAnalyticsFactory.query(
        { growing_season: $rootScope.selected_growing_season.value },
        function(data) {
          $scope.crowdsourceApps.push({
            title: 'Oilseed Rape Sclerotinia',
            description: 'Flowering Stages of OSR',
            category: ['Crop growth', 'Crop development'],
            featured_image: [
              { image_file: require('../../csfb/images/oilseedrape.jpg') }
            ],
            URL: '/dashboard/crowdsource/#/osrsclerotinia/',
            stats: {
              growing_season: data.growing_season,
              observation_dates: {
                earliest: data.analytics.earliest,
                latest: data.analytics.latest
              },
              observations_recorded: {
                total: data.analytics.count,
                positive: 70,
                negative: 10,
                neutral: 20
              }
            }
          });
        }
      );
    }

    $scope.callFunction = function(name) {
      if (angular.isFunction($scope[name])) $scope[name]();
    };

    $scope.changeDataView = function(selectedDataView) {
      $scope.currentDataView = selectedDataView;
    };

    $scope.toggleSideBar = function() {
      $scope.sidebar.toggle();
      $scope.sidebar_path =
        '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/mapSidebar/information_sidebar.html';
    };

    $rootScope.$watch('selectedFeature', function(newValue, oldValue) {
      if (newValue) {
        $scope.sidebar.show();
        $scope.showEdit = false;
        $scope.feature = newValue.feature;
        if (typeof newValue.options.layer === 'undefined') {
          newValue.options.layer = newValue.options.title;
        }
        switch (newValue.options.layer) {
          case 'FarmLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/fms_app/myfarm/map/sidebar/farm/farm-sidebar.directive.html';
            break;
          case 'OtherUsersResponseLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/mapSidebar/osr_response_data_sidebar.html';
            break;
          case 'MonitoringSiteLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/mapSidebar/osr_monitoring_site_data_sidebar.html';
            break;
          default:
            $scope.sidebar.toggle();
            break;
        }
      }
    });

    //Drawing on Map
    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    $scope.draw_marker = function() {
      add_marker();
    };

    $scope.redirecttoMyFarm = function() {
      window.open('/dashboard/farm/#/', '_blank');
    };

    $scope.redirecttoSclerotiniaRiskView = function() {
      window.open('/dashboard/dss/#/pestdisease/osr/sclerotinia/', '_blank');
    };

    function add_marker() {
      leafletData.getMap(mapid).then(function(map) {
        var marker = new L.Draw.Marker(map, {});
        marker.enable();
      });
    }

    $scope.cancelAddEditFeature = function() {
      $rootScope.post_save_signal();
    };

    $rootScope.post_save_signal = function() {
      $scope.remove_drawn_layer();
      if ($rootScope.selectedFeature) {
        $rootScope.selectedFeature.edited = false;
      }
    };

    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    leafletData.getMap(mapid).then(function(map) {
      leafletData.getLayers().then(function(baselayers) {
        $scope.drawnItems = baselayers.overlays.draw;
        map.on('draw:created', function(e) {
          if (layer) {
            $scope.remove_drawn_layer();
          }
          var type = e.layerType;
          layer = e.layer;
          if (type === 'marker') {
            $rootScope.geocoder = {};
            GenericMapService.getMarkerGeocode($rootScope, layer, function() {
              $rootScope.response = {};
              $rootScope.response['location'] = $rootScope.geocoder.location;

              $mdDialog.show({
                controller: 'OSRGrowthResponseRecordController',
                templateUrl:
                  '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/forms/osrg-form.html',
                locals: {
                  custom_growth_status_list: $scope.custom_growth_status_list,
                  previous_status_record_date:
                    $scope.previous_status_record_date,
                  growing_season: $rootScope.selected_growing_season.value
                },
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                escapeToClose: true
              });
              $scope.drawnItems.addLayer(layer);
            });
          }
        });
      });
    });

    TeamsOfUserFactory.query(function(result) {
      $scope.team_list = result;
    });

    OilSeedRapeGrowthConstantsFactory.get(function(data) {
      $scope.team_id = data.primary_team.team_id;
      $scope.growth_status_list = data.growth_status_list;
      $scope.custom_growth_status_list = angular.copy(data.growth_status_list);
      $scope.planting_season_current = data.current_planting_season;
      $rootScope.leaflet_monitoring_sites_layer_id = -9999;
      $rootScope.monitoring_sites_geoJson = data.monitoring_sites[0];
      $rootScope.monitoring_sites_geoJsonLayer = {};
      $rootScope.monitoring_sites_clusterLayer = {};
      GenericMapService.load_monitoring_site(
        mapid,
        leafletData,
        $rootScope,
        data.monitoring_sites[0]
      );
    });

    if (typeof $rootScope.selected_growing_season !== 'undefined') {
      GenericMapService.loadCSResponseData(
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        OilSeedRapeGrowthFactory
      );
      getCrowdsourceAppStats($scope, OilSeedRapeGrowthAnalyticsFactory);
    }

    $scope.zoomtolocation = function() {
      angular.extend($rootScope, {
        center: {
          zoom: 18,
          autoDiscover: true
        }
      });
    };

    $scope.zoomtoExtent = function() {
      angular.extend($rootScope, {
        center: {
          lat: 54.5,
          lng: -1.89,
          zoom: 5
        }
      });
    };
  });
