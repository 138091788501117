(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("EditStandardYieldReplicateController", EditStandardYieldReplicateController);

    EditStandardYieldReplicateController.$inject = ["$scope", "replicate_form", "$mdDialog", "plants", "stems", "dry_matter", "index"];

    function EditStandardYieldReplicateController($scope, replicate_form, $mdDialog, plants, stems, dry_matter, index) {
        var ctrl = this;
        $scope.editReplicate = editReplicate;
        $scope.newYieldDigReplicate = {};
        $scope.EditYieldReplicateForm = [
            {
                className: "flex-33",
                type: "input",
                key: "plants",
                defaultValue: plants,
                templateOptions: {
                    label: "Plants",
                    required: true
                }
            },
            {
                className: "flex-33",
                type: "input",
                key: "stems",
                defaultValue: stems,
                templateOptions: {
                    label: "Stems",
                    required: true
                }
            },
            {
                className: "flex-33",
                type: "input",
                key: "dry_matter",
                defaultValue: dry_matter,
                templateOptions: {
                    label: "Dry Matter",
                    required: false
                }
            }
        ];

        $scope.EditYieldReplicateForm = $scope.EditYieldReplicateForm.concat(replicate_form);

        function editReplicate() {
            $scope.yield_dig_assessment.replicates[index] = $scope.newYieldDigReplicate;
            $mdDialog.hide();
        }

    }

})(angular);