(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("YieldDigVisualisationController", YieldDigVisualisationController);

    YieldDigVisualisationController.$inject = ["$scope", "$rootScope", "$filter", "$route", "$routeParams", "$mdDialog", "$khMessage", "NgTableParams", "PotatoYieldDigDataLoader", "SelectedTeamService"];

    function YieldDigVisualisationController($scope, $rootScope, $filter, $route, $routeParams, $mdDialog, $khMessage, NgTableParams, PotatoYieldDigDataLoader, SelectedTeamService) {
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Initialisation and configuration
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $rootScope.page_id = "potatodashboard";
        $scope.adminStatus = $route.current.$$route.admin;
        if ($scope.adminStatus) {
            $rootScope.teamSlug = $routeParams.team_slug;
            $rootScope.sub_app_page_id = "potatodashboard_admin";
            $rootScope.displayAdminNavLinks = true;
            $rootScope.currentNavItem = "yieldDigsAdmin";
        } else {
            $rootScope.sub_app_page_id = null;
            $rootScope.currentNavItem = "yieldDigs";
        }
        $rootScope.view_only_irrigation = false;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Controller API
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.viewYieldDig = viewYieldDig;
        $scope.editYieldDig = editYieldDig;
        $scope.deleteYieldDig = deleteYieldDig;

        $scope.plotFilterConfig = {"fpc_name": {id: "text", placeholder: "Filter by name"}};
        $scope.varietyNameFilterConfig = {"variety_name": {id: "text", placeholder: "Filter by name"}};
        $scope.digDateFilterConfig = {"yield_dig_date_string": {id: "text", placeholder: "Filter by date"}};
        $scope.rowsSampledFilterConfig = {"rows_sampled": {id: "number", placeholder: "Filter by value"}};
        $scope.digLengthFilterConfig = {"dig_length": {id: "text", placeholder: "Filter by value"}};
        $scope.replicatesCountFilterConfig = {"replicates_count": {id: "number", placeholder: "Filter by value"}};
        $scope.plantPopulationFilterConfig = {"plant_population": {id: "number", placeholder: "Filter by value"}};
        $scope.stemPopulationFilterConfig = {"stem_population": {id: "number", placeholder: "Filter by value"}};
        $scope.tuberPopulationFilterConfig = {"tuber_population": {id: "number", placeholder: "Filter by value"}};
        $scope.impliedYieldFilterConfig = {"implied_yield": {id: "number", placeholder: "Filter by value"}};
        $scope.dryMatterFilterConfig = {"dry_matter": {id: "number", placeholder: "Filter by value"}};

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Internal API: Function definitions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function loadYieldDigsSummary() {
            if ($scope.adminStatus) {
                PotatoYieldDigDataLoader.yieldDigAdminSummary($scope, $scope.teamSlug, handleYieldDigSummarySuccess, handleYieldDigSummaryError);
            } else {
                PotatoYieldDigDataLoader.yieldDigSummary($scope, SelectedTeamService.getSelectedSlug(), handleYieldDigSummarySuccess, handleYieldDigSummaryError);
            }
        }

        function handleYieldDigSummarySuccess(scope, data) {
            scope.YieldDigSummaryTableData = data;
            scope.YieldDigSummaryTable = new NgTableParams({count: 10}, {
                counts: [10, 15, 20, 50],
                dataset: data
            });
        }

        function handleYieldDigSummaryError(scope) {
            $khMessage.show($filter("translate")("request_failed"), 5000);
            scope.YieldDigSummaryTableData = null;
            scope.YieldDigSummaryTable.reload();
        }

        function viewYieldDig(yield_dig_slug) {
            $mdDialog.show({
                controller: "PotatoYieldDigDetailController",
                skipHide: true,
                multiple: true,
                templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/yield_dig_visualisation/detail/PotatoYieldDigDetailDialogue.html",
                locals: {
                    yield_dig_slug: yield_dig_slug
                },
                clickOutsideToClose: true
            });
        }

        function editYieldDig(yield_dig_slug) {
            PotatoYieldDigDataLoader.yieldDigResult($scope, yield_dig_slug, handleSuccess, handleError);

            function handleSuccess(scope, data) {
                $mdDialog.show({
                    controller: "AddYieldDigController",
                    skipHide: true,
                    multiple: true,
                    templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/AddYieldDig.html",
                    locals: {
                        yieldDigSlug: yield_dig_slug,
                        yieldDigResult: data
                    },
                    clickOutsideToClose: true
                });
            }

            function handleError(scope, error) {
            }
        }

        function deleteYieldDig(yieldDigSlug) {
            PotatoYieldDigDataLoader.yieldDigDelete($scope, yieldDigSlug, handleYieldDigDeleteSuccess, handleYieldDigDeleteError);
        }

        function handleYieldDigDeleteSuccess(scope, yieldDigSlug) {
            $khMessage.show($filter("translate")("deletion_success"), 5000);
            angular.forEach(scope.YieldDigSummaryTableData, function (element, idx, obj) {
                if (element.yield_dig_slug === yieldDigSlug) {
                    scope.YieldDigSummaryTableData.splice(idx, 1);
                    scope.YieldDigSummaryTable.reload();
                }
            });
        }

        function handleYieldDigDeleteError() {
            $khMessage.show($filter("translate")("deletion_failed"), 5000);
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Watchers and Filter routines
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.$on("$destroy", function () {
            onSecNavFilterUpdate();
            onRefreshPage();
        });
        var onSecNavFilterUpdate = $scope.$on("filter:selections_updated",
            function () {
                loadYieldDigsSummary();
            }
        );
        var onRefreshPage = $scope.$on("$routeChangeSuccess",
            function (scope, next, previous) {
                if (previous) {
                    loadYieldDigsSummary();
                }
            }
        );

    }

})(angular);
