angular.module('CabbageStemFleaApp').controller("CSFBResponseRecordController",
    function ($location, $scope, $rootScope, $routeParams, $khMessage, $mdDialog, $khPulse, leafletData,
              CabbageStemFleaFactory, growing_season, TeamsOfUserFactory, member_id, GenericMapService) {
        $scope.response = $rootScope.response;
        $scope.response.growing_season = growing_season;
        var mapid = 'landingMap';
        // Form
        var load_csfb_formly_fields = function () {
            return [
                {
                    className: "flex-100",
                    type: "input",
                    key: "growing_season",
                    id: "response_growing_season",
                    templateOptions: {
                        disabled: true,
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Growing Season"
                    }
                },
                {
                    className: "flex-100",
                    type: 'radio',
                    key: "cultivation_impact",
                    defaultValue: 'StoppedCropping',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "What effect has cabbage stem flea beetle infestation on your cropping decision?",
                        placeholder: "What effect has cabbage stem flea beetle infestation on your cropping decision?",
                        options: [{name: 'Given up growing OSR', value: 'StoppedCropping'},
                            {name: 'Oilseed rape in this field', value: 'NoChange'}]
                    }
                },
                {
                    className: "flex-50",
                    type: 'searchable_select',
                    key: "variety",
                    defaultValue: 'Conventional',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Select Variety Type",
                        placeholder: "Select variety type",
                        options: [{name: 'Hybrid', value: 'Hybrid'},
                            {name: 'Conventional', value: 'Conventional'}]
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-50",
                    type: "datepicker",
                    key: "readable_sowing_date",
                    defaultValue: new Date(),
                    templateOptions: {
                        minDate: new Date(new Date().getFullYear(), 0, 1),
                        maxDate: new Date(),
                        format: 'DD MMMM YYYY',
                        label: "Sowing Date",
                        required: true
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-50",
                    type: 'searchable_select',
                    key: "preemergence_herbicide",
                    // defaultValue: 'no',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Pre-Emergence Herbicide Used?",
                        placeholder: "Pre-emergence herbicide used?",
                        options: [{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}]
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-50",
                    type: 'searchable_select',
                    key: "crop_establishment",
                    defaultValue: 'Plough',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Crop Establishment",
                        placeholder: "Crop establishment",
                        options: [
                            {name: 'Autocast', value: 'Auto Cast'},
                            {name: 'Direct drill', value: 'Direct Drill'},
                            {name: 'Non-inversion', value: 'Non-Inversion'},
                            {name: 'Plough', value: 'Plough'},
                            {name: 'Sub-cast', value: 'Sub Cast'}
                        ]
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-50",
                    type: "input",
                    key: "cropped_area",
                    // defaultValue: 0,
                    templateOptions: {
                        label: "Cropped area in this field (ha)",
                        required: true,
                        type: "number",
                        min: 0.1,
                        max: 999.999
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-50",
                    type: "input",
                    key: "no_of_insecticides",
                    defaultValue: 0,
                    templateOptions: {
                        label: "No of Insecticides Applied",
                        required: true,
                        type: "number",
                        min: 0
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-100",
                    type: 'searchable_select',
                    key: "seed_treatment",
                    defaultValue: 'None',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Seed Treatment",
                        placeholder: "Seed treatment",
                        options: [{name: 'Neonicotinoid', value: 'Neonicotinoid'}, {
                            name: 'Other',
                            value: 'Other'
                        }, {name: 'None', value: 'None'}]
                    },
                    expressionProperties: {
                        hide: true
                    },
                },
                {
                    className: 'flex-100',
                    template: '<hr /><div><strong>Cabbage Stem Flea Beetle Damage Assessment</strong></div>',
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                },
                {
                    className: "flex-100",
                    type: 'searchable_select',
                    key: "percent_crop_damaged",
                    defaultValue: 0,
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Damage Assessment Category",
                        placeholder: "Damage assessment category",
                        options: [
                            {name: 'No Damage (0-10%)', value: 0},
                            {name: 'Mild damage (10-40%)', value: 30},
                            {name: 'Moderate damage (35-65%)', value: 50},
                            {name: 'Severe damage (65-90%)', value: 80},
                            {name: 'Complete crop loss (90-100%)', value: 100}
                        ]
                    },
                    expressionProperties: {
                        hide: function ($viewValue, $modelValue, scope) {
                            return (scope.model.cultivation_impact === 'StoppedCropping');
                        }
                    },
                }
            ];
        };

        setUpForm();
        function setUpForm() {
            $scope.response_form = load_csfb_formly_fields()
        }

        $scope.cancelAddEditFeature = function () {
            if (typeof($rootScope.selectedFeature) !== 'undefined') {
                $rootScope.post_save_signal();
            }
            else {
                $rootScope.post_save_signal();
            }
        };

        $scope.save_response = function () {
            if ($rootScope.geocoder) {
                $scope.response.latitude = $rootScope.geocoder.latitude;
                $scope.response.longitude = $rootScope.geocoder.longitude;
                $scope.response.region = $rootScope.geocoder.sub_region;
                $scope.response.member_id = member_id;
            }

            var percentageCalculation = computePercentCropDamaged()

            for (var key in $scope.response) {
                if ($scope.response[key] == null) {
                    delete $scope.response[key];
                }
            }

            var timeOffset = new Date().getTimezoneOffset();
            if ($scope.response.readable_observation_date) {
                $scope.response.observation_date = $scope.response.readable_observation_date.toJSON();
            }

            if ($scope.response.readable_sowing_date) {
                $scope.response.sowing_date = $scope.response.readable_sowing_date.toJSON();
            }

            if ($scope.response.slug) {
                update_response();
            } else {
                if ($scope.response.cultivation_impact === "StoppedCropping") {
                    $khMessage.show("Stopped Cropping", 5000);
                    create_response();
                } else {
                    $khMessage.show("Sum of percent damage is 100", 5000);
                    create_response();
                }
            }

        };

        function computePercentCropDamaged() {
            $scope.response["percent_crop_low_concern"] = 0;
            $scope.response["percent_crop_moderate_concern"] = 0;
            $scope.response["percent_crop_severe_concern"] = 0;
            $scope.response["percent_crop_written_off"] = 0;

            $scope.response["percent_crop_growing_well"] = 100 - $scope.response.percent_crop_damaged;
            switch ($scope.response.percent_crop_damaged) {
                case 30:
                    $scope.response["percent_crop_low_concern"] = $scope.response.percent_crop_damaged;
                    break;
                case 50:
                    $scope.response["percent_crop_moderate_concern"] = $scope.response.percent_crop_damaged;
                    break;
                case 80:
                    $scope.response["percent_crop_severe_concern"] = $scope.response.percent_crop_damaged;
                    break;
                case 100:
                    $scope.response["percent_crop_written_off"] = $scope.response.percent_crop_damaged;
                    break;
            }
            return true;
        }

        function create_response() {
            CabbageStemFleaFactory.save($scope.response, function (success_data) {
                    if (success_data) {
                        //load_pointdata(CabbageStemFleaFactory, $rootScope, leafletData)
                        if (typeof($rootScope.other_user_responses_geoJsonLayer) == 'undefined' || $rootScope.leaflet_other_user_responses_layer_id === -9999) {
                            GenericMapService.loadCSFBResponseData($khPulse, $rootScope, leafletData, mapid, CabbageStemFleaFactory);
                        }
                        else {
                            GenericMapService.load_CSFBResponsedata(CabbageStemFleaFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }

        function update_response() {
            CabbageStemFleaFactory.update($scope.response, function (success_data) {
                    if (success_data) {
                        if (typeof($rootScope.users_responses_geoJsonLayer) == 'undefined') {
                            GenericMapService.loadCSFBResponseData($khPulse, $rootScope, leafletData, mapid, CabbageStemFleaFactory);
                        }
                        else {
                            GenericMapService.load_CSFBResponsedata(CabbageStemFleaFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }
    });
