angular.module('CabbageStemFleaApp').factory("CabbageStemFleaFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/cabbagestemflea/', {}, {
        query: {
            isArray: true
        },
        save: {
            method: 'POST'
        }
    });
}]);

angular.module('CabbageStemFleaApp').factory("CabbageStemFleaAnalyticsFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/cabbagestemfleaanalytics/', {}, {
        query: {
            isArray: false
        }
    });
}]);
