angular
  .module('CabbageStemFleaApp')
  .controller('CabbageStemFleaController', function(
    $scope,
    $rootScope,
    $mdDialog,
    $routeParams,
    NgTableParams,
    $khPulse,
    leafletData,
    CabbageStemFleaFactory,
    CabbageStemFleaAnalyticsFactory,
    PlatformSettingsFactory,
    GenericMapService,
    CrowdsourceMapSpeedDialOptions
  ) {
    $rootScope.pageTitle = 'Crowdsource';
    $rootScope.page_id = 'crowdsource';
    $rootScope.sub_app_page_id = 'crowdsource_osr_cabbagestemflea';
    var mapid = 'landingMap';

    $scope.participated = 0;
    $scope.memberId = $routeParams.id;
    if (!$scope.memberId) {
      $scope.memberId = 'Anonymous';
    }

    PlatformSettingsFactory.get(function(data) {
      $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
      $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
      $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
      $scope.default_latval = data.DEFAULT_CENTER_LAT;
      $scope.default_lngval = data.DEFAULT_CENTER_LONG;
      $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
      if (typeof $rootScope.selected_growing_season === 'undefined') {
        $rootScope.selected_growing_season = {};
        $rootScope.selected_growing_season.name =
          $scope.planting_season_current;
        $rootScope.selected_growing_season.value =
          $scope.planting_season_current;
        $scope.$broadcast('filter:selected_growing_season');
      }
    });

    $scope.myCustomMarker = L.Icon.extend({
      options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 12),
        iconSize: new L.Point(24, 24),
        iconUrl:
          '/static/assets/khapps/crowdsource/csfb/images/small/bug_dark_green_size_2.png'
      }
    });

    $scope.csfb = {};
    $scope.csfbdata = {};
    $scope.invalidPercentage = false;
    $scope.maxValue = 100;
    $scope.csfb['seed_treatment'] = 'None';
    $scope.csfb['variety'] = 'Hybrid';

    $scope.dataAnalysisCards = [
      {
        card_title: 'Response Analytics',
        kh_card_content_template:
          '/static/assets/khapps/crowdsource/dashboard/partials/includes/AnalyticsStatsTable.html',
        //kh_card_toolbar_filters_template: '/static/assets/khapps/fms_app/farm/partials/fms_table_nav_links.html',
        card_flex: 100
      }
    ];

    $scope.$on('filter:selections_updated', function(obj, filterValue) {
      if (filterValue.hasOwnProperty('growing_season')) {
        getCrowdsourceAppStats($scope, CabbageStemFleaAnalyticsFactory);
        if (typeof $rootScope.users_responses_geoJsonLayer == 'undefined') {
          GenericMapService.loadCSFBResponseData(
            $khPulse,
            $rootScope,
            leafletData,
            mapid,
            CabbageStemFleaFactory
          );
        } else {
          GenericMapService.load_CSFBResponsedata(
            CabbageStemFleaFactory,
            $khPulse,
            $rootScope,
            leafletData,
            mapid
          );
        }
      }
    });

    $rootScope.$on('changedselectedFeature', function() {
      GenericMapService.changed_selection($rootScope, $scope);
    });

    function getCrowdsourceAppStats($scope, CabbageStemFleaAnalyticsFactory) {
      $scope.crowdsourceApps = [];
      CabbageStemFleaAnalyticsFactory.query(
        { growing_season: $rootScope.selected_growing_season.value },
        function(data) {
          $scope.crowdsourceApps.push({
            title: 'Oilseed Rape Flea Beetle',
            description: 'Cabbage Stem Flea Beetle Pest Infestation',
            category: ['Crop disease', 'Crop pest'],
            featured_image: [
              {
                image_file:
                  '/static/assets/khapps/crowdsource/csfb/images/CSFB.jpeg'
              }
            ],
            URL: '/dashboard/crowdsource/#/osrcabbagestemflea/',
            stats: {
              growing_season: data.growing_season,
              observation_dates: {
                earliest: data.analytics.earliest,
                latest: data.analytics.latest
              },
              observations_recorded: {
                total: data.analytics.count,
                positive: 70,
                negative: 10,
                neutral: 20
              }
            }
          });
        }
      );
    }

    $scope.callFunction = function(name) {
      if (angular.isFunction($scope[name])) $scope[name]();
    };

    $scope.changeDataView = function(selectedDataView) {
      $scope.currentDataView = selectedDataView;
    };

    var layer;
    layer = GenericMapService.buildmap(
      $scope,
      $rootScope,
      leafletData,
      mapid,
      CrowdsourceMapSpeedDialOptions
    );

    $scope.toggleSideBar = function() {
      $scope.sidebar.toggle();
      $scope.sidebar_path =
        '/static/assets/khapps/crowdsource/CabbageStemFlea/partials/mapSidebar/information_sidebar.html';
    };
    $rootScope.$watch('selectedFeature', function(newValue, oldValue) {
      if (newValue) {
        $scope.sidebar.show();
        $scope.showEdit = false;
        $scope.feature = newValue.feature;
        if (typeof newValue.options.layer === 'undefined') {
          newValue.options.layer = newValue.options.title;
        }
        switch (newValue.options.layer) {
          case 'OtherUsersResponseLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/crowdsource/CabbageStemFlea/partials/mapSidebar/csfb_response_data_sidebar.html';
            break;
          default:
            if (newValue.feature.properties.hasOwnProperty('crop')) {
              $scope.sidebar_path =
                '/static/assets/khapps/crowdsource/CabbageStemFlea/partials/mapSidebar/csfb_response_data_sidebar.html';
            } else {
              $scope.sidebar.toggle();
            }
            break;
        }
      }
    });

    //Drawing on Map
    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    $scope.draw_marker = function() {
      add_marker();
    };

    function add_marker() {
      leafletData.getMap(mapid).then(function(map) {
        var marker = new L.Draw.Marker(map, {});
        marker.enable();
      });
    }

    $scope.cancelAddEditFeature = function() {
      $rootScope.post_save_signal();
    };

    $rootScope.post_save_signal = function() {
      $scope.remove_drawn_layer();
      if ($rootScope.selectedFeature) {
        $rootScope.selectedFeature.edited = false;
      }
    };

    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    leafletData.getMap(mapid).then(function(map) {
      leafletData.getLayers().then(function(baselayers) {
        $scope.drawnItems = baselayers.overlays.draw;
        map.on('draw:created', function(e) {
          if (layer) {
            $scope.remove_drawn_layer();
          }
          var type = e.layerType;
          layer = e.layer;
          if (type === 'marker') {
            $rootScope.geocoder = {};
            GenericMapService.getMarkerGeocode($rootScope, layer, function() {
              $rootScope.response = {};
              $rootScope.response['location'] = $rootScope.geocoder.location;

              $mdDialog.show({
                controller: 'CSFBResponseRecordController',
                templateUrl:
                  '/static/assets/khapps/crowdsource/CabbageStemFlea/partials/forms/csfb-form.html',
                locals: {
                  growing_season: $rootScope.selected_growing_season.value,
                  member_id: $scope.memberId
                },
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                escapeToClose: true
              });
              $scope.drawnItems.addLayer(layer);
            });
          }
        });
      });
    });

    if (typeof $rootScope.selected_growing_season !== 'undefined') {
      GenericMapService.loadCSFBResponseData(
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        CabbageStemFleaFactory
      );
      getCrowdsourceAppStats($scope, CabbageStemFleaAnalyticsFactory);
    }

    $scope.zoomtolocation = function() {
      angular.extend($rootScope, {
        center: {
          zoom: 18,
          autoDiscover: true
        }
      });
    };

    $scope.zoomtoExtent = function() {
      angular.extend($rootScope, {
        center: {
          lat: 54.5,
          lng: -1.89,
          zoom: 5
        }
      });
    };
  });
