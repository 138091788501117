angular.module('OilSeedRapeGrowthApp').factory("OilSeedRapeGrowthFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/oilseedrapegrowth/', {}, {
        save: {
            method: 'POST'
        }
    });
}]);

angular.module('OilSeedRapeGrowthApp').factory("OilSeedRapeGrowthAnalyticsFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/oilseedrapegrowthanalytics/', {}, {
        query: {
            isArray: false
        }
    });
}]);
