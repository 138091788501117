(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("AddGradeProfileTemplateController", AddGradeProfileTemplateController);

    AddGradeProfileTemplateController.$inject = ["$scope", "$rootScope", "$filter", "$mdDialog", "$khMessage", "gradingTemplateHelpers"];

    function AddGradeProfileTemplateController($scope, $rootScope, $filter, $mdDialog, $khMessage, gradingTemplateHelpers) {
        var ctrl = this;
        $scope.saveTemplate = saveTemplate;
        $scope.isValid = isValid;
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.newTemplateForm = gradingTemplateHelpers.newTemplateForm({
            lower_bound: 0,
            upper_bound: 20
        });
        $scope.newTemplateModel = {
            grading_profile: [
                {
                    is_current_partition: true,
                    partition_lower_bound: 0,
                    partition_upper_bound: 20
                }
            ]
        };
        $scope.newTemplateOptions = {
            formState: {
                disabled: true
            }
        };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Initialisation and configuration
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //$rootScope.page_id = "";
        //$rootScope.sub_app_page_id = null;
        //$rootScope.currentNavItem = "";

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Controller API
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //$scope.functionName = functionName;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Internal API: Function definitions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function saveTemplate(newTemplateModel) {
            var dataForSubmission = angular.copy(newTemplateModel);
            gradingTemplateHelpers.saveNewTemplate(dataForSubmission, saveSuccessCallback, saveErrorCallback);
        }

        function saveSuccessCallback(success) {
            $khMessage.show("Template successfully saved", 4000);
            $mdDialog.hide();
        }

        function saveErrorCallback(error) {
            $khMessage.show("Template failed to save. Please check for missing values and then try again", 4000);
        }

        function isValid() {
            if ($scope.newTemplateModel.grading_profile) {
                if (!$scope.newTemplateModel.grading_profile[$scope.newTemplateModel.grading_profile.length - 1].hasOwnProperty("is_final_partition")) {
                    return false;
                } else if (!$scope.newTemplateModel.grading_profile[$scope.newTemplateModel.grading_profile.length - 1]["is_final_partition"]) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Watchers and Filter routines
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //$scope.$on('$destroy', function () {
        //    onSecNavFilterUpdate();
        //   onRefreshPage();
        //});
        //var onSecNavFilterUpdate = $scope.$on("filter:selections_updated",
        //    function () {
        //        addFunctionHere()
        //    }
        //);
        //var onRefreshPage = $scope.$on('$routeChangeSuccess',
        //    function (scope, next, previous) {
        //        if (previous) {
        //            addFunctionHere()
        //        }
        //    }
        //);


    }

})(angular);
