(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("EditCustomYieldReplicateController", EditCustomYieldReplicateController);

    EditCustomYieldReplicateController.$inject = ["$scope", "replicate_form", "$mdDialog", "plants", "stems", "dry_matter", "index", "CustomGradingHelpers"];

    function EditCustomYieldReplicateController($scope, replicate_form, $mdDialog, plants, stems, dry_matter, index, CustomGradingHelpers) {
        var ctrl = this;
        $scope.editReplicate = editReplicate;
        $scope.EditYieldReplicateForm = CustomGradingHelpers.newReplicateForm();
        $scope.newYieldDigReplicate = {
            plants: plants,
            stems: stems,
            dry_matter: dry_matter,
            grade_profile: replicate_form
        };
        $scope.isValid = isValid;

        // Submit replicate to yield dig object
        function editReplicate() {
            $scope.yield_dig_assessment.replicates[index] = $scope.newYieldDigReplicate;
            $mdDialog.hide();
        }

        function isValid() {
            if (!$scope.newYieldDigReplicate.grade_profile[$scope.newYieldDigReplicate.grade_profile.length - 1].hasOwnProperty("is_final_partition")) {
                // Invalid if the last element does not have the is_final_partition property
                return false;
            } else if (!$scope.newYieldDigReplicate.grade_profile[$scope.newYieldDigReplicate.grade_profile.length - 1]["is_final_partition"]) {
                // Invalid if that property is not true
                return false;
            } else {
                return true;
            }
        }

    }

})(angular);