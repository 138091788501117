angular.module('OilSeedRapeGrowthApp').controller("OSRGrowthResponseRecordController",
    function ($location, $scope, $rootScope, $routeParams, $khMessage, $mdDialog, $khPulse, leafletData,
              OilSeedRapeGrowthFactory, custom_growth_status_list, previous_status_record_date, growing_season, GenericMapService) {
        $scope.response = $rootScope.response;
        $scope.response.growing_season = growing_season;
        var mapid = 'landingMap';

        var load_osrg_formly_fields = function (custom_growth_status_list, previous_status_record_date) {
            return [
                {
                    className: "flex-100",
                    type: "input",
                    key: "growing_season",
                    id: "response_growing_season",
                    templateOptions: {
                        disabled: true,
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Growing Season",
                        //placeholder: "Select planting season",
                        //options: $rootScope.selected_growing_season
                    }
                },
                {
                    className: "flex-100",
                    type: 'searchable_select',
                    key: "status",
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Select Flowering Status",
                        placeholder: "Select flowering status",
                        options: custom_growth_status_list
                    }
                },
                {
                    className: "flex-100",
                    type: "datepicker",
                    key: "readable_observation_date",
                    defaultValue: new Date(),
                    templateOptions: {
                        minDate: previous_status_record_date,
                        maxDate: new Date(),
                        format: 'DD MMMM YYYY',
                        label: "Observation Date",
                        required: true,
                        //placeholder: "Enter observation date"
                    },
                    validation: {
                        messages: {
                            validDate: function () {
                                return "Please enter valid date. Date should be in past and after your previous record date at same site."
                            }
                        }
                    },
                    validators: {
                        validDate: {
                            expression: function (viewValue, modelValue) {
                                if (modelValue) {
                                    var selected_date = moment(modelValue, 'DD MMMM YYYY').format("YYYY-MM-DDT00:00:00")
                                    var todays_date = moment().format("YYYY-MM-DDT00:00:00");
                                    var previous_status_date;
                                    if (previous_status_record_date) {
                                        previous_status_date = moment(previous_status_record_date).format("YYYY-MM-DDT00:00:00");
                                    }
                                    if (todays_date >= selected_date) {
                                        if (previous_status_date > selected_date) {
                                            return false
                                        }
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false;
                                }
                            }
                        }
                    }
                }
            ];
        };

        setUpForm();
        function setUpForm() {
            $scope.response_form = load_osrg_formly_fields(custom_growth_status_list, previous_status_record_date)
        }

        $scope.cancelAddEditFeature = function () {
            if (typeof($rootScope.selectedFeature) !== 'undefined') {
                $rootScope.post_save_signal();
            }
            else {
                $rootScope.post_save_signal();
            }
        };

        $scope.save_response = function () {
            if ($rootScope.geocoder) {
                $scope.response.latitude = $rootScope.geocoder.latitude;
                $scope.response.longitude = $rootScope.geocoder.longitude;
                $scope.response.region = $rootScope.geocoder.sub_region
            }

            for (var key in $scope.response) {
                if ($scope.response[key] == null) {
                    delete $scope.response[key];
                }
            }

            var timeOffset = new Date().getTimezoneOffset();
            if ($scope.response.readable_observation_date) {
                $scope.response.observation_date = $scope.response.readable_observation_date.toJSON();
            }

            if ($scope.response.slug) {
                update_response();
            } else {
                create_response();
            }

        };

        function create_response() {
            OilSeedRapeGrowthFactory.save($scope.response, function (success_data) {
                    if (success_data) {
                        if (typeof($rootScope.users_responses_geoJsonLayer) == 'undefined') {
                            GenericMapService.loadCSResponseData($khPulse, $rootScope, leafletData, mapid, OilSeedRapeGrowthFactory)
                        }
                        else {
                            GenericMapService.load_CSResponsedata(OilSeedRapeGrowthFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }

        function update_response() {
            OilSeedRapeGrowthFactory.update($scope.response, function (success_data) {
                    if (success_data) {
                        if (typeof($rootScope.users_responses_geoJsonLayer) == 'undefined') {
                            GenericMapService.loadCSResponseData($khPulse, $rootScope, leafletData, mapid, OilSeedRapeGrowthFactory)
                        }
                        else {
                            GenericMapService.load_CSResponsedata(OilSeedRapeGrowthFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }
    });
