(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("PotatoYieldDigDataLoader", PotatoYieldDigDataLoaderFactory);

    PotatoYieldDigDataLoaderFactory.$inject = ["PotatoYieldDigAssessmentResults", "PotatoYieldDigResultsAdmin"];

    function PotatoYieldDigDataLoaderFactory(PotatoYieldDigAssessmentResults, PotatoYieldDigResultsAdmin) {

        var yieldDigSummaryData;
        var currentYieldDigSlug;
        var currentYieldDigResultSlug;
        var yieldDigDetailData;
        var yieldDigResultData;

        var yieldDigAdminSummaryData;

        return {

            yieldDigSummary: function (scope, team_slug, successCallback, errorCallback) {
                var query_params = {team_slug: team_slug, representation: "summary"};
                if (scope.selected_growing_season) {
                    query_params["growing_season"] = scope.selected_growing_season.value;
                }
                PotatoYieldDigAssessmentResults.query(query_params, function (success) {
                    scope.showEmptyState = !success.length;
                    successCallback(scope, yieldDigSummaryData = success);
                }, function (error) {
                    errorCallback(scope, error);
                });
            },

            yieldDigAdminSummary: function (scope, teamSlug, successCallback, errorCallback) {
                var query_params = {teamSlug: teamSlug, representation: "summary"};
                if (scope.selected_growing_season) {
                    query_params["growing_season"] = scope.selected_growing_season.value;
                }
                PotatoYieldDigResultsAdmin.get(query_params, function (success) {
                    scope.showEmptyState = !success.length;
                    successCallback(scope, yieldDigAdminSummaryData = success);
                }, function (error) {
                    errorCallback(scope, error);
                });
            },

            yieldDigDetail: function (scope, yieldDigSlug, successCallback, errorCallback) {
                if ((yieldDigDetailData && currentYieldDigSlug === yieldDigSlug)) {
                    successCallback(scope, yieldDigDetailData);
                }
                else {
                    PotatoYieldDigAssessmentResults.get({
                        yield_dig_slug: yieldDigSlug,
                        representation: "detail"
                    }, function (success) {
                        currentYieldDigSlug = yieldDigSlug;
                        successCallback(scope, yieldDigDetailData = success);
                    }, function (error) {
                        errorCallback(scope);
                    });
                }
            },

            yieldDigResult: function (scope, yieldDigSlug, successCallback, errorCallback) {
                if ((yieldDigDetailData && currentYieldDigSlug === yieldDigSlug)) {
                    successCallback(scope, yieldDigDetailData);
                }
                else {
                    PotatoYieldDigAssessmentResults.get({yield_dig_slug: yieldDigSlug}, function (success) {
                        currentYieldDigResultSlug = yieldDigSlug;
                        successCallback(scope, yieldDigResultData = success);
                    }, function (error) {
                        errorCallback(scope, error);
                    });
                }
            },

            yieldDigDelete: function (scope, yieldDigSlug, successCallback, errorCallback) {
                PotatoYieldDigAssessmentResults.delete({yield_dig_slug: yieldDigSlug}, function (success) {
                    successCallback(scope, yieldDigSlug);
                }, function (error) {
                    errorCallback(scope);
                });
            },

        };

    }

})(angular);
