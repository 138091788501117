angular.module('OilSeedRapeGrowthApp').config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'OSR Growth Map',
                controller: 'OSRGrowthMapController',
                templateUrl: '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/osr_growth_dashboard_landing.html'
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);