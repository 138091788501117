(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("gradingTemplateHelpers", gradingTemplateHelpersFactory);


    gradingTemplateHelpersFactory.$inject = ["yieldDigGradingTemplate"];

    function gradingTemplateHelpersFactory(yieldDigGradingTemplate) {

        var helpers = {

            newTemplateForm: function (args) {
                return [
                    {
                        className: "flex-100",
                        key: "name",
                        type: "input",
                        templateOptions: {
                            label: "Template name"
                        }
                    },
                    {
                        template: "<md-divider></md-divider>",
                        className: "flex-100 margin-bottom-20"
                    },
                    {
                        className: "layout-column",
                        fieldGroup: [
                            {
                                key: "grading_profile",
                                className: "flex-100",
                                type: "templateRepeatSection",
                                templateOptions: {
                                    btnText: "Add another partition",
                                    fields: [
                                        helpers.newTemplateFields(args)
                                    ]
                                }
                            }
                        ]
                    }
                ];
            },


            newTemplateFields: function (args) {
                return {
                    className: "layout-row",
                    fieldGroup: [
                        {
                            className: "flex",
                            type: "checkbox",
                            key: "is_final_partition",
                            defaultValue: false,
                            templateOptions: {
                                label: "Final partition?",
                                disabled: true
                            },
                            hideExpression: "true"
                        }, {
                            className: "flex",
                            type: "checkbox",
                            key: "is_current_partition",
                            defaultValue: true,
                            templateOptions: {
                                label: "Current partition?"
                            },
                            hideExpression: "true"
                        }, {
                            className: "flex-50",
                            type: "input",
                            key: "partition_lower_bound",
                            defaultValue: args.lower_bound ? args.lower_bound : 0,
                            templateOptions: {
                                label: "Lower size bound (mm)",
                                required: true,
                                type: "number",
                                disabled: true
                            }
                        },
                        {
                            className: "flex-50",
                            type: "input",
                            key: "partition_upper_bound",
                            defaultValue: args.upper_bound ? args.upper_bound : 20,
                            templateOptions: {
                                label: "Upper size bound (mm)",
                                required: true,
                                type: "number"
                            },
                            expressionProperties: {
                                "templateOptions.disabled": "model.is_final_partition || !model.is_current_partition",
                                "templateOptions.required": "!model.is_final_partition"
                            }
                        }
                    ]

                };
            },

            saveNewTemplate: function (formData, saveSuccessCallback, saveErrorCallback) {
                var submissionData = helpers.generateApiSubmissionData(formData);
                yieldDigGradingTemplate.save(submissionData, saveSuccessCallback, saveErrorCallback);
            },

            generateApiSubmissionData: function (formData) {
                var grading_profile = [];
                angular.forEach(formData.grading_profile, function (elem, idx, obj) {
                    grading_profile.push({
                        lower_bound: elem.partition_lower_bound,
                        upper_bound: elem.partition_upper_bound
                    });
                });
                var submissionData = {
                    name: formData.name,
                    grading_profile: grading_profile
                };
                return submissionData;
            }

        };

        return helpers;

    }

})(angular);

