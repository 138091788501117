(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .factory('YieldDigParentForm', YieldDigParentFormFactory);

    YieldDigParentFormFactory.$inject = ['yieldDigGradingTemplate', '$khMessage'];

    function YieldDigParentFormFactory(yieldDigGradingTemplate, $khMessage) {
        var forms = {
            fetch: function (scope) {
                return [
                    {
                        key: 'sample_date',
                        className: 'flex-33',
                        type: 'datepicker',
                        defaultValue: new Date(),
                        templateOptions: {
                            label: 'Sample Date',
                            required: true
                        }
                    }, {
                        key: 'field_plot_crop',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'value',
                            labelProp: 'name',
                            label: 'Crop',
                            options: scope.field_plot_crops,
                            required: true
                        }
                    },
                    {
                        key: 'dig_length',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'value',
                            labelProp: 'name',
                            label: 'Dig Length',
                            options: scope.dig_lengths,
                            required: true
                        }
                    },
                    {
                        key: 'rows_sampled',
                        className: 'flex-33',
                        type: 'input',
                        templateOptions: {
                            label: 'Number of Rows Sampled Per Replicate',
                            required: true,
                            type: 'number'
                        }
                    },
                    {
                        key: 'profile_type',
                        className: 'flex-33',
                        type: 'select',
                        defaultValue: 'STANDARD',
                        templateOptions: {
                            label: 'Grading Profile Type',
                            required: true,
                            options: [
                                {name: 'Standard', value: 'STANDARD'},
                                {name: 'Custom', value: 'CUSTOM'},
                                {name: 'Template', value: 'TEMPLATE'}
                            ]
                        },
                        expressionProperties: {
                            'templateOptions.disabled': 'model.replicates.length > 0'
                        }
                    },
                    {
                        key: 'increment_size',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'value',
                            labelProp: 'name',
                            label: 'Increment Size',
                            options: scope.increment_sizes,
                            required: true
                        },
                        hideExpression: 'model.profile_type !== "STANDARD"',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.replicates.length > 0'
                        }
                    }, {
                        key: 'starting_size',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'value',
                            labelProp: 'name',
                            label: 'Starting Increment',
                            options: scope.starting_sizes,
                            required: true
                        },
                        hideExpression: 'model.profile_type !== "STANDARD"',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.replicates.length > 0'
                        }
                    }, {
                        key: 'ending_size',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'value',
                            labelProp: 'name',
                            label: 'Finishing Increment',
                            options: scope.ending_sizes,
                            required: true
                        },
                        hideExpression: 'model.profile_type !== "STANDARD"',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.replicates.length > 0'
                        }
                    },
                    {
                        key: 'template',
                        className: 'flex-33',
                        type: 'searchable_select',
                        templateOptions: {
                            valueProp: 'slug',
                            labelProp: 'name',
                            label: 'Template',
                            options: scope.replicateTemplates,
                            required: true
                        },
                        hideExpression: 'model.profile_type !== "TEMPLATE"',
                        expressionProperties: {
                            'templateOptions.disabled': 'model.replicates.length > 0'
                        },
                        // controller: function ($scope, yieldDigGradingTemplate, $khMessage) {
                        //     yieldDigGradingTemplate.query({}, function (success) {
                        //         $scope.to.options = success;
                        //     }, function (error) {
                        //         $khMessage.show("Unable to load yield dig templates, please refresh and try again", 4000);
                        //     })
                        // }
                    },
                ];
            }
        };
        return forms;
    }
})(angular);
