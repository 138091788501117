(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("StandardGradingHelpers", StandardGradingHelpersFactory);

    function StandardGradingHelpersFactory() {

        var helpers = {
            standardReplicateForm: function (scope) {
                var replicateFields = [];
                scope.grade_profile = helpers.generateGradeSizesArray(scope.yield_dig_assessment);
                angular.forEach(scope.grade_profile, function (grade) {
                    var __ret = helpers.processGradeIntoFormFieldProperties(grade);
                    replicateFields.push(helpers.newReplicateRow(__ret.rep_key_count, __ret.rep_label_count, __ret.rep_key_weight, __ret.rep_label_weight));
                });
                return replicateFields;
            },

            editStandardReplicateForm: function (scope, index) {
                var selectedReplicate = scope.yield_dig_assessment.replicates[index];
                var replicateFields = [];
                angular.forEach(scope.grade_profile, function (grade) {
                    var __ret = helpers.processGradeIntoFormFieldProperties(grade);
                    replicateFields.push(helpers.newReplicateRow(__ret.rep_key_count, __ret.rep_label_count, __ret.rep_key_weight, __ret.rep_label_weight, selectedReplicate[__ret.rep_key_count], selectedReplicate[__ret.rep_key_weight]));
                });
                return replicateFields;
            },

            restructureStandardReplicate: function (replicate, dataset, scope) {
                var restructuredReplicate = {
                    plants: replicate.plants,
                    stems: replicate.stems,
                    dry_matter: replicate.dry_matter,
                    dig_length: dataset.dig_length,
                    rows_sampled: dataset.rows_sampled
                };
                var grade_profile = [];
                angular.forEach(scope.grade_profile, function (grade, key) {
                    grade_profile.push({
                        grade_size: {
                            lower_bound: grade.lowerBound ? grade.lowerBound : 0,
                            upper_bound: grade.upperBound
                        },
                        count: helpers.extractCountKey(grade, replicate),
                        weight: helpers.extractWeightKey(grade, replicate)
                    });
                });
                restructuredReplicate.grade_profile = grade_profile;
                return restructuredReplicate;
            },

            generateGradeSizesArray: function (assessment_form) {
                var gradingProfile = [];
                var current_value = assessment_form.starting_size;
                var previous_value = null;
                while (current_value <= assessment_form.ending_size) {
                    gradingProfile.push({lowerBound: previous_value, upperBound: current_value});
                    previous_value = current_value;
                    current_value += assessment_form.increment_size;
                }
                gradingProfile.push({lowerBound: previous_value, upperBound: null});
                return gradingProfile;
            },

            processGradeIntoFormFieldProperties: function (grade) {
                return {
                    rep_key_count: helpers.getFieldKey(grade, "count"),
                    rep_label_count: helpers.getFieldLabel("Count: ", grade, " mm"),
                    rep_key_weight: helpers.getFieldKey(grade, "weight"),
                    rep_label_weight: helpers.getFieldLabel("Weight (g): ", grade, " mm")
                };
            },

            newReplicateRow: function (rep_key_count, rep_label_count, rep_key_weight, rep_label_weight, count_value, weight_value) {
                return {
                    className: "layout-row",
                    fieldGroup: [
                        {
                            className: "flex-33",
                            type: "input",
                            key: rep_key_count,
                            defaultValue: count_value ? count_value : 0,
                            templateOptions: {
                                label: rep_label_count,
                                required: true
                            }
                        },
                        {
                            className: "flex-33",
                            type: "input",
                            key: rep_key_weight,
                            defaultValue: weight_value ? weight_value : 0,
                            templateOptions: {
                                label: rep_label_weight,
                                required: true
                            }
                        }
                    ]

                };
            },

            getFieldKey: function (grade, fieldName) {
                if (grade.lowerBound && grade.upperBound) {
                    return fieldName + "_" + grade.lowerBound.toString() + "_" + grade.upperBound.toString();
                } else if (grade.lowerBound && !grade.upperBound) {
                    return fieldName + "_" + grade.lowerBound.toString() + "_null";
                } else if (!grade.lowerBound && grade.upperBound) {
                    return fieldName + "_null_" + grade.upperBound.toString();
                }
            },

            getFieldLabel: function (initialString, grade, endingString) {
                if (grade.lowerBound && grade.upperBound) {
                    return initialString + grade.lowerBound.toString() + " - " + grade.upperBound.toString() + endingString;
                } else if (!grade.lowerBound && grade.upperBound) {
                    return initialString + "<" + grade.upperBound.toString() + endingString;
                } else if (grade.lowerBound && !grade.upperBound) {
                    return initialString + ">" + grade.lowerBound.toString() + endingString;
                }
            },


            extractCountKey: function (grade, grading_data) {
                var match = null;
                angular.forEach(grading_data, function (value, key) {
                    if (key === helpers.getFieldKey(grade, "count")) {
                        match = value;
                    }
                });
                return match;
            },

            extractWeightKey: function (grade, grading_data) {
                var match = null;
                angular.forEach(grading_data, function (value, key) {
                    if (key === helpers.getFieldKey(grade, "weight")) {
                        match = value;
                    }
                });
                return match;
            },


            checkStandardReplicateSetup: function (yield_dig_assessment) {
                if (yield_dig_assessment.starting_size && yield_dig_assessment.ending_size && yield_dig_assessment.increment_size) {
                    return false;
                }
                else {
                    return true;
                }
            }

        };

        return helpers;

    }

})(angular);
