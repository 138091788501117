angular.module('WheatYellowRustDiseaseApp').factory("WheatYellowRustDiseaseFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/wheatyellowrust/', {}, {
        save: {
            method: 'POST'
        }
    });
}]);

angular.module('WheatYellowRustDiseaseApp').factory("WheatYellowRustDiseaseAnalyticsFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/crowdsource/wheatyellowrustanalytics/', {}, {
        query: {
            isArray: false
        }
    });
}]);
