(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("TemplateGradingReplicateHelpers", TemplateGradingReplicateHelpersFactory);

    TemplateGradingReplicateHelpersFactory.$inject = [];

    function TemplateGradingReplicateHelpersFactory() {

        var helpers = {

            templateReplicateForm: function (scope) {
                var replicateForm = [];
                scope.selectedTemplate = helpers.getTemplate(scope.yield_dig_assessment.template, scope.replicateTemplates);
                angular.forEach(scope.selectedTemplate.grading_profile, function (element, idx, obj) {
                    var gradeDetail = helpers.processGradeIntoFormFieldProperties(element);
                    replicateForm.push(helpers.newReplicateRow(gradeDetail.rep_key_count, gradeDetail.rep_label_count,
                        gradeDetail.rep_key_weight, gradeDetail.rep_label_weight)
                    );
                });
                return replicateForm;
            },

            editTemplateReplicateForm: function (scope, index) {
                var selectedReplicate = scope.yield_dig_assessment.replicates[index];
                var replicateFields = [];
                angular.forEach(scope.selectedTemplate.grading_profile, function (grade) {
                    var grade_detail = helpers.processGradeIntoFormFieldProperties(grade);
                    replicateFields.push(helpers.newReplicateRow(grade_detail.rep_key_count, grade_detail.rep_label_count,
                        grade_detail.rep_key_weight, grade_detail.rep_label_weight, selectedReplicate[grade_detail.rep_key_count],
                        selectedReplicate[grade_detail.rep_key_weight]));
                });
                return replicateFields;
            },

            processGradeIntoFormFieldProperties: function (grade) {
                return {
                    rep_key_count: helpers.getFieldKey(grade, "count"),
                    rep_label_count: helpers.getFieldLabel("Count: ", grade, " mm"),
                    rep_key_weight: helpers.getFieldKey(grade, "weight"),
                    rep_label_weight: helpers.getFieldLabel("Weight (g): ", grade, " mm")
                };
            },

            getFieldKey: function (grade, fieldName) {
                if (grade.lower_bound && grade.upper_bound) {
                    return fieldName + "_" + grade.lower_bound.toString() + "_" + grade.upper_bound.toString();
                } else if (grade.lower_bound && !grade.upper_bound) {
                    return fieldName + "_" + grade.lower_bound.toString() + "_null";
                } else if (!grade.lower_bound && grade.upper_bound) {
                    return fieldName + "_null_" + grade.upper_bound.toString();
                }
            },

            getFieldLabel: function (initialString, grade, endingString) {
                if (grade.lower_bound && grade.upper_bound) {
                    return initialString + grade.lower_bound.toString() + " - " + grade.upper_bound.toString() + endingString;
                } else if (!grade.lower_bound && grade.upper_bound) {
                    return initialString + "<" + grade.upper_bound.toString() + endingString;
                } else if (grade.lower_bound && !grade.upper_bound) {
                    return initialString + ">" + grade.lower_bound.toString() + endingString;
                }
            },

            newReplicateRow: function (rep_key_count, rep_label_count, rep_key_weight, rep_label_weight, count_value, weight_value) {
                return {
                    className: "layout-row",
                    fieldGroup: [
                        {
                            className: "flex-33",
                            type: "input",
                            key: rep_key_count,
                            defaultValue: count_value ? count_value : 0,
                            templateOptions: {
                                label: rep_label_count,
                                required: true
                            }
                        },
                        {
                            className: "flex-33",
                            type: "input",
                            key: rep_key_weight,
                            defaultValue: weight_value ? weight_value : 0,
                            templateOptions: {
                                label: rep_label_weight,
                                required: true
                            }
                        }
                    ]

                };
            },

            getTemplate: function (templateSlug, listOfTemplates) {
                var template = null;
                angular.forEach(listOfTemplates, function (element, idx, obj) {
                    if (element.slug === templateSlug) {
                        template = element;
                    }
                });
                return template;
            },

            restructureTemplateReplicate: function (replicate, dataset, scope) {
                var restructuredReplicate = {
                    plants: replicate.plants,
                    stems: replicate.stems,
                    dry_matter: replicate.dry_matter,
                    dig_length: dataset.dig_length,
                    rows_sampled: dataset.rows_sampled
                };
                var grade_profile = [];
                angular.forEach(scope.selectedTemplate.grading_profile, function (grade, key) {
                    grade_profile.push({
                        grade_size: {
                            lower_bound: grade.lower_bound,
                            upper_bound: grade.upper_bound
                        },
                        count: helpers.extractCountKey(grade, replicate),
                        weight: helpers.extractWeightKey(grade, replicate)
                    });
                });
                restructuredReplicate.grade_profile = grade_profile;
                return restructuredReplicate;
            },

            extractCountKey: function (grade, grading_data) {
                var match = null;
                angular.forEach(grading_data, function (value, key) {
                    if (key === helpers.getFieldKey(grade, "count")) {
                        match = value;
                    }
                });
                return match;
            },

            extractWeightKey: function (grade, grading_data) {
                var match = null;
                angular.forEach(grading_data, function (value, key) {
                    if (key === helpers.getFieldKey(grade, "weight")) {
                        match = value;
                    }
                });
                return match;
            }
        };

        return helpers;

    }

})(angular);
