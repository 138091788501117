angular.module('WheatYellowRustDiseaseApp').controller("WYRResponseRecordController",
    function ($location, $scope, $rootScope, $routeParams, $khMessage, $mdDialog, $khPulse, leafletData,
              WheatYellowRustDiseaseFactory, crop_variety_list, previous_status_record_date, growing_season,
              member_id, GenericMapService) {
        $scope.response = $rootScope.response;
        $scope.response.growing_season = growing_season;
        $scope.response.readable_observation_date = moment(new Date()).toDate();
        $scope.response.member_id = member_id;
        var mapid = 'landingMap';

        var load_formly_fields = function (crop_variety_list, previous_status_record_date) {
            return [
                {
                    className: "flex-100",
                    type: "input",
                    key: "growing_season",
                    id: "response_growing_season",
                    hideExpression: 'true',
                    templateOptions: {
                        disabled: true,
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Growing Season",
                    }
                },
                {
                    className: "flex-100",
                    type: 'searchable_select',
                    key: "variety",
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: "Select Variety",
                        placeholder: "Select variety",
                        options: crop_variety_list
                    }
                },
                {
                    className: "flex-100",
                    type: "datepicker",
                    key: "readable_observation_date",
                    templateOptions: {
                        minDate: previous_status_record_date,
                        maxDate: moment(new Date()).toDate(),
                        format: 'DD MMMM YYYY',
                        label: "Observation Date",
                        required: true,
                    },
                    hideExpression: 'true',
                }
            ];
        };

        setUpForm();
        function setUpForm() {
            $scope.response_form = load_formly_fields(crop_variety_list, previous_status_record_date);
        }

        $scope.cancelAddEditFeature = function () {
            if (typeof($rootScope.selectedFeature) !== 'undefined') {
                $rootScope.post_save_signal();
            }
            else {
                $rootScope.post_save_signal();
            }
        };

        $scope.save_response = function () {
            if ($rootScope.geocoder) {
                $scope.response.latitude = $rootScope.geocoder.latitude;
                $scope.response.longitude = $rootScope.geocoder.longitude;
                $scope.response.region = $rootScope.geocoder.sub_region
            }

            for (var key in $scope.response) {
                if ($scope.response[key] == null) {
                    delete $scope.response[key];
                }
            }

            var timeOffset = new Date().getTimezoneOffset();
            if ($scope.response.readable_observation_date) {
                $scope.response.observation_date = $scope.response.readable_observation_date.toJSON();
            }

            if ($scope.response.slug) {
                update_response();
            } else {
                create_response();
            }

        };

        function create_response() {
            WheatYellowRustDiseaseFactory.save($scope.response, function (success_data) {
                    if (success_data) {
                        if (typeof($rootScope.users_responses_geoJsonLayer) == 'undefined') {
                            GenericMapService.loadCSResponseData($khPulse, $rootScope, leafletData, mapid, WheatYellowRustDiseaseFactory)
                        }
                        else {
                            GenericMapService.load_CSResponsedata(WheatYellowRustDiseaseFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                    $rootScope.crowdsourceApps[0].stats.observations_recorded.total = $rootScope.crowdsourceApps[0].stats.observations_recorded.total + 1;
                    $rootScope.crowdsourceApps[0].stats.observation_dates.latest = $scope.response.observation_date;

                    if (success_data.properties.adult_resistance) {
                        titleMessage = "This is a very unusual record";
                        textMessage = "If possible please send a sample to <strong>NIAB</strong>." +
                            "<br>" +
                            "<br>" +
                            "Sampling instructions <strong><a target='_blank' href='https://s3-eu-west-1.amazonaws.com/niabprod/media/niab_sampling_instructions.pdf'>here</a></strong>.";
                        alert = $mdDialog.alert({
                            title: titleMessage,
                            htmlContent: textMessage,
                            ok: 'Close'
                        });

                        $mdDialog
                            .show(alert)
                            .finally(function () {
                                alert = undefined;
                            });
                    }
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }

        function update_response() {
            WheatYellowRustDiseaseFactory.update($scope.response, function (success_data) {
                    if (success_data) {
                        if (typeof($rootScope.users_responses_geoJsonLayer) == 'undefined') {
                            GenericMapService.loadCSResponseData($khPulse, $rootScope, leafletData, mapid, WheatYellowRustDiseaseFactory)
                        }
                        else {
                            GenericMapService.load_CSResponsedata(WheatYellowRustDiseaseFactory, $khPulse, $rootScope, leafletData, mapid);
                        }
                    }
                    $rootScope.post_save_signal();
                    $mdDialog.hide();
                    $khMessage.show("Created successfully", 5000);
                }, function (error) {
                    if (error.status) {
                        $mdDialog.hide();
                        $khMessage.show("Creation failed");
                        $rootScope.post_save_signal();
                    }
                }
            );
        }
    });
