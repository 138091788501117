(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("CustomGradingHelpers", CustomGradingHelpersFactory);

    function CustomGradingHelpersFactory() {

        var helpers = {
            customReplicateForm: function (scope) {
                var replicateFields = [];
                replicateFields.push(helpers.newReplicateRow());
                return replicateFields;
            },

            initialReplicateData: function () {
                return {
                    grade_profile: [
                        {
                            partition_lower_bound: 0,
                            partition_upper_bound: 20,
                            partition_count: 0,
                            partition_weight: 0,
                            is_current_partition: true,
                            is_final_partition: false
                        }
                    ]
                };
            },

            editCustomReplicateForm: function (scope, index) {
                var selectedReplicate = scope.yield_dig_assessment.replicates[index];
                return selectedReplicate.grade_profile;
            },

            restructureCustomReplicate: function (replicate, dataset) {
                var restructuredReplicate = {
                    plants: replicate.plants,
                    stems: replicate.stems,
                    dry_matter: replicate.dry_matter,
                    dig_length: dataset.dig_length,
                    rows_sampled: dataset.rows_sampled
                };
                var grade_profile = [];
                angular.forEach(replicate.grade_profile, function (grade, key) {
                    grade_profile.push({
                        grade_size: {
                            lower_bound: grade.partition_lower_bound,
                            upper_bound: grade.partition_upper_bound
                        },
                        count: grade.partition_count,
                        weight: grade.partition_weight
                    });
                });
                restructuredReplicate.grade_profile = grade_profile;
                return restructuredReplicate;
            },

            newReplicateForm: function (plants, stems, dry_matter, lower_bound, upper_bound, count, weight) {
                return [
                    {
                        className: "layout-row",
                        fieldGroup: [
                            {
                                key: "plants",
                                className: "flex-33",
                                type: "input",
                                defaultValue: plants ? plants : 0,
                                templateOptions: {
                                    label: "Plants",
                                    required: true,
                                    type: "number"
                                }
                            },
                            {
                                key: "stems",
                                className: "flex-33",
                                type: "input",
                                defaultValue: stems ? stems : 0,
                                templateOptions: {
                                    label: "Stems",
                                    required: true,
                                    type: "number"
                                }
                            },
                            {
                                key: "dry_matter",
                                className: "flex-33",
                                type: "input",
                                defaultValue: dry_matter ? dry_matter : null,
                                templateOptions: {
                                    label: "Dry Matter",
                                    required: false,
                                    type: "number"
                                }
                            }
                        ]
                    },
                    {
                        className: "layout-column",
                        fieldGroup: [
                            {
                                key: "grade_profile",
                                className: "flex-100",
                                type: "replicateRepeatSection",
                                templateOptions: {
                                    btnText: "Add another partition",
                                    fields: [
                                        helpers.newReplicateRow(lower_bound, upper_bound, count, weight)
                                    ]
                                }
                            }
                        ]
                    }
                ];
            },

            newReplicateRow: function (lower_bound, upper_bound, count, weight) {
                return {
                    className: "layout-row",
                    fieldGroup: [
                        {
                            key: "is_current_partition",
                            className: "flex",
                            type: "checkbox",
                            defaultValue: true,
                            templateOptions: {
                                label: "Current partition?"
                            },
                            hideExpression: "true"
                        }, {
                            key: "is_final_partition",
                            className: "flex",
                            type: "checkbox",
                            defaultValue: false,
                            templateOptions: {
                                label: "Final partition?",
                                disabled: true
                            },
                            hideExpression: "true"
                        }, {
                            key: "partition_lower_bound",
                            className: "flex-25",
                            type: "input",
                            defaultValue: lower_bound ? lower_bound : 0,
                            templateOptions: {
                                label: "Lower size bound (mm)",
                                required: true,
                                type: "number",
                                disabled: true
                            }
                        }, {
                            key: "partition_upper_bound",
                            className: "flex-25",
                            type: "input",
                            defaultValue: upper_bound ? upper_bound : 20,
                            templateOptions: {
                                label: "Upper size bound (mm)",
                                required: true,
                                type: "number"
                            },
                            expressionProperties: {
                                "templateOptions.disabled": "!model.is_current_partition || model.is_final_partition",
                                "templateOptions.required": "!model.is_final_partition"
                            }
                        }, {
                            key: "partition_count",
                            className: "flex-25",
                            type: "input",
                            defaultValue: count ? count : 0,
                            templateOptions: {
                                label: "Tuber count",
                                required: true,
                                type: "number"
                            }
                        }, {
                            key: "partition_weight",
                            className: "flex-25",
                            type: "input",
                            defaultValue: weight ? weight : 0,
                            templateOptions: {
                                label: "Tubers weight (g)",
                                required: true,
                                type: "number"
                            }
                        }
                    ]

                };
            },

            getFieldKey: function (grade, fieldName) {
                if (grade.lowerBound && grade.upperBound) {
                    return fieldName + "_" + grade.lowerBound.toString() + "_" + grade.upperBound.toString();
                } else if (grade.lowerBound && !grade.upperBound) {
                    return fieldName + "_" + grade.lowerBound.toString() + "_null";
                } else if (!grade.lowerBound && grade.upperBound) {
                    return fieldName + "_null_" + grade.upperBound.toString();
                }
            },

            getFieldLabel: function (initialString, grade, endingString) {
                if (grade.lowerBound && grade.upperBound) {
                    return initialString + grade.lowerBound.toString() + " - " + grade.upperBound.toString() + endingString;
                } else if (!grade.lowerBound && grade.upperBound) {
                    return initialString + "<" + grade.upperBound.toString() + endingString;
                } else if (grade.lowerBound && !grade.upperBound) {
                    return initialString + ">" + grade.lowerBound.toString() + endingString;
                }
            },

            checkStandardReplicateSetup: function (yield_dig_assessment) {
                if (yield_dig_assessment.starting_size && yield_dig_assessment.ending_size && yield_dig_assessment.increment_size) {
                    return false;
                }
                else {
                    return true;
                }
            }

        };

        return helpers;

    }

})(angular);
