angular.module('WheatYellowRustDiseaseApp').config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'WYR Disease Map',
                controller: 'WYRDiseaseMapController',
                templateUrl: '/static/assets/khapps/crowdsource/WheatYellowRustDisease/partials/wyr_disease_dashboard_landing.html'
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);