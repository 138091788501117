(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("PotatoYieldDigDetailController", PotatoYieldDigDetailController);

    PotatoYieldDigDetailController.$inject = ["$scope", "$mdDialog", "yield_dig_slug", "PotatoYieldDigDataLoader"];

    function PotatoYieldDigDetailController($scope, $mdDialog, yield_dig_slug, PotatoYieldDigDataLoader) {

        $scope.yieldDigSlug = yield_dig_slug;
        loadYieldDigDetail();

        function loadYieldDigDetail() {
            PotatoYieldDigDataLoader.yieldDigDetail($scope, yield_dig_slug, handleYieldDigDetailSuccess, handleYieldDigDetailError);
        }

        function handleYieldDigDetailSuccess(scope, data) {
            scope.YieldDigDetail = data;
        }

        function handleYieldDigDetailError(scope, error) {
            scope.YieldDigDetail = null;
        }

    }

})(angular);