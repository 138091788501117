angular.module('CabbageStemFleaApp').run(["$http","$rootScope",
    function ($http, $rootScope) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        $rootScope.in_app_links_template = "";
        $rootScope.view_as_template = "/static/assets/khapps/crowdsource/CabbageStemFlea/partials/includes/secondary_navbar_views.html";
        $rootScope.select_filters_template = "/static/assets/khapps/crowdsource/CabbageStemFlea/partials/includes/secondary_navbar_filters.html";

        $rootScope.mapview = true;
        $rootScope.cardview = false;

        $rootScope.views = [
            {name: 'Map View', value: 'mapview', icon: 'google-maps'},
            {name: 'Card View', value: 'cardview', icon: 'view-grid'},
        ];

        $rootScope.changeView = function (view) {
            switch (view) {
                case "mapview":
                    $rootScope.mapview = true;
                    $rootScope.cardview = false;
                    break;
                case "cardview":
                    $rootScope.mapview = false;
                    $rootScope.cardview = true;
                    break;
            }
        };
        
    }]);