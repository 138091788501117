(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("AddCustomYieldReplicateController", AddCustomYieldReplicateController);

    AddCustomYieldReplicateController.$inject = ["$scope", "$mdDialog", "CustomGradingHelpers"];

    function AddCustomYieldReplicateController($scope, $mdDialog, CustomGradingHelpers) {
        var ctrl = this;
        $scope.addReplicate = addReplicate;
        $scope.newYieldDigReplicate = CustomGradingHelpers.initialReplicateData();
        $scope.CustomReplicateForm = CustomGradingHelpers.newReplicateForm();
        $scope.isValid = isValid;

        // Submit replicate to yield dig object
        function addReplicate() {
            $scope.yield_dig_assessment.replicates.push($scope.newYieldDigReplicate);
            $mdDialog.hide();
        }

        function isValid() {
            if (!$scope.newYieldDigReplicate.grade_profile[$scope.newYieldDigReplicate.grade_profile.length - 1].hasOwnProperty("is_final_partition")) {
                // Invalid if the last element does not have the is_final_partition property
                return false;
            } else if (!$scope.newYieldDigReplicate.grade_profile[$scope.newYieldDigReplicate.grade_profile.length - 1]["is_final_partition"]) {
                // Invalid if that property is not true
                return false;
            } else {
                return true;
            }
        }

    }

})(angular);