(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("AddYieldReplicateController", AddYieldReplicateController);

    AddYieldReplicateController.$inject = ["$scope", "replicate_form", "$mdDialog"];

    function AddYieldReplicateController($scope, replicate_form, $mdDialog) {
        var ctrl = this;
        $scope.addReplicate = addReplicate;
        $scope.newYieldDigReplicate = {};
        $scope.ReplicateFormRow = [
            {
                className: "flex-33",
                type: "input",
                key: "plants",
                defaultValue: 0,
                templateOptions: {
                    label: "Plants",
                    required: true
                }
            },
            {
                className: "flex-33",
                type: "input",
                key: "stems",
                defaultValue: 0,
                templateOptions: {
                    label: "Stems",
                    required: true
                }
            },
            {
                className: "flex-33",
                type: "input",
                key: "dry_matter",
                defaultValue: null,
                templateOptions: {
                    label: "Dry Matter",
                    required: false,
                    type: 'number'
                }
            }
        ];

        $scope.ReplicateFormRow = $scope.ReplicateFormRow.concat(replicate_form);

        function addReplicate() {
            $scope.yield_dig_assessment.replicates.push($scope.newYieldDigReplicate);
            $mdDialog.hide();
        }

    }

})(angular);