angular.module('CabbageStemFleaApp').config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                controller: "CabbageStemFleaController",
                templateUrl: "/static/assets/khapps/crowdsource/CabbageStemFlea/partials/cabbage_stem_flea_dashboard_landing.html"

            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);
