angular.module('OilSeedRapeGrowthApp').run(["$http", "$rootScope",
    function ($http, $rootScope) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        $rootScope.view_as_template = "/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/includes/secondary_navbar_views.html";
        $rootScope.select_filters_template = "/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/includes/secondary_navbar_filters.html";

        $rootScope.tableview = false;
        $rootScope.mapview = true;

        $rootScope.views = [
            {name: 'Map View', value: 'mapview', icon: 'google-maps'},
            {name: 'Table View', value: 'tableview', icon: 'table'}
        ];

        $rootScope.changeView = function (view) {
            $rootScope.activeView = view;
            switch (view) {
                case "mapview":
                    $rootScope.tableview = false;
                    $rootScope.mapview = true;
                    $rootScope.cardview = false;
                    break;
                case "tableview":
                    $rootScope.tableview = true;
                    $rootScope.mapview = false;
                    $rootScope.cardview = false;
                    break;
                case "cardview":
                    $rootScope.tableview = false;
                    $rootScope.mapview = false;
                    $rootScope.cardview = true;
                    break;
            }
        };

    }]);