(function (angular) {
    "use strict";

    angular.module("PotatoApp")
        .controller("PotatoSecondaryNavController", PotatoSecondaryNavController);

    PotatoSecondaryNavController.$inject = ["$scope", "$mdDialog", "$window"];


    function PotatoSecondaryNavController($scope, $mdDialog, $window) {

        $scope.addYieldDigAssessment = function () {
            $mdDialog.show({
                animation: true,
                templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_edit_yield_dig/AddYieldDig.html",
                controller: "AddYieldDigController",
                locals: {
                    yieldDigSlug: null,
                    yieldDigResult: null
                },
                skipHide: true, 
                multiple: true
            }).then(function () {
                $mdDialog.hide();
            });
        };

        $scope.addYieldDigTemplate = function () {
            $mdDialog.show({
                animation: true,
                templateUrl: "/static/assets/khapps/crops/potato/yield_dig_assessment/add_grade_profile_template/AddGradeProfileTemplate.html",
                controller: "AddGradeProfileTemplateController",
                skipHide: true, 
                multiple: true
            }).then(function () {
                $mdDialog.hide();
            });
        };

        $scope.openUserGuide = openUserGuide;

        function openUserGuide() {
            openUserGuideWindow();
        }

        function openUserGuideWindow() {
            $window.open("https://support.kisanhub.com/support/solutions/17000079490", "_blank");
        }
    }

})(angular);