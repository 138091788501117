angular
  .module('WheatYellowRustDiseaseApp')
  .controller('WYRDiseaseMapController', function(
    $scope,
    $rootScope,
    $khMessage,
    $mdDialog,
    $routeParams,
    $khPulse,
    NgTableParams,
    leafletData,
    WheatYellowRustDiseaseFactory,
    FarmFactory,
    TeamsOfUserFactory,
    WheatYellowRustDiseaseConstantsFactory,
    WheatYellowRustDiseaseAnalyticsFactory,
    PlatformSettingsFactory,
    GenericMapService,
    CrowdsourceMapSpeedDialOptions
  ) {
    $rootScope.pageTitle = 'Crowdsource';
    $rootScope.page_id = 'crowdsource';
    $rootScope.sub_app_page_id = 'crowdsource_wheat_yellowrust';
    $scope.memberId = $routeParams.id;
    if (!$scope.memberId) {
      $scope.memberId = 'Anonymous';
    }
    var mapid = 'landingMap';

    var promise = new Promise(function(resolve, reject) {
      PlatformSettingsFactory.get(function(data) {
        $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
        $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
        $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
        $scope.default_latval = data.DEFAULT_CENTER_LAT;
        $scope.default_lngval = data.DEFAULT_CENTER_LONG;
        $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
        if (typeof $rootScope.selected_growing_season === 'undefined') {
          $rootScope.selected_growing_season = {};
          $rootScope.selected_growing_season.name =
            $scope.planting_season_current;
          $rootScope.selected_growing_season.value =
            $scope.planting_season_current;
          $scope.$broadcast('filter:selected_growing_season');
        }
        resolve($scope);
      });
    });
    promise.catch(function() {});

    var layer;
    layer = GenericMapService.buildmap(
      $scope,
      $rootScope,
      leafletData,
      mapid,
      CrowdsourceMapSpeedDialOptions
    );

    $scope.dataAnalysisCards = [
      {
        card_title: 'Response Analytics',
        kh_card_content_template:
          '/static/assets/khapps/crowdsource/dashboard/partials/includes/AnalyticsStatsTable.html',
        //kh_card_toolbar_filters_template: '/static/assets/khapps/fms_app/farm/partials/fms_table_nav_links.html',
        card_flex: 100
      }
    ];

    $rootScope.$on('changedselectedFeature', function() {
      GenericMapService.changed_selection($rootScope, $scope);
    });

    $scope.$on('filter:selections_updated', function(obj, filterValue) {
      if (filterValue.hasOwnProperty('growing_season')) {
        getCrowdsourceAppStats($scope, WheatYellowRustDiseaseAnalyticsFactory);
        if (typeof $rootScope.users_responses_geoJsonLayer == 'undefined') {
          GenericMapService.loadCSResponseData(
            $khPulse,
            $rootScope,
            leafletData,
            mapid,
            WheatYellowRustDiseaseFactory
          );
        } else {
          GenericMapService.load_CSResponsedata(
            WheatYellowRustDiseaseFactory,
            $khPulse,
            $rootScope,
            leafletData,
            mapid
          );
        }
      }
    });

    function getCrowdsourceAppStats(
      $scope,
      WheatYellowRustDiseaseAnalyticsFactory
    ) {
      $rootScope.crowdsourceApps = [];
      WheatYellowRustDiseaseAnalyticsFactory.query(
        { growing_season: $rootScope.selected_growing_season.value },
        function(data) {
          $rootScope.crowdsourceApps.push({
            title: 'Wheat Yellow Rust',
            description: 'Wheat Yellow Rust Disease',
            category: ['Crop growth', 'Crop development'],
            featured_image: [
              {
                image_file:
                  '/static/assets/khapps/crowdsource/csfb/images/wheatyellowrust.jpg'
              }
            ],
            URL: '/dashboard/crowdsource/#/wheatyellowrust/',
            stats: {
              growing_season: data.growing_season,
              observation_dates: {
                earliest: data.analytics.earliest,
                latest: data.analytics.latest
              },
              observations_recorded: {
                total: data.analytics.count,
                positive: 70,
                negative: 10,
                neutral: 20
              }
            }
          });
        }
      );
    }

    $scope.callFunction = function(name) {
      if (angular.isFunction($scope[name])) $scope[name]();
    };

    $scope.changeDataView = function(selectedDataView) {
      $scope.currentDataView = selectedDataView;
    };

    $scope.toggleSideBar = function() {
      $scope.sidebar.toggle();
      $scope.sidebar_path =
        '/static/assets/khapps/crowdsource/WheatYellowRustDisease/partials/mapSidebar/information_sidebar.html';
    };

    $rootScope.$watch('selectedFeature', function(newValue, oldValue) {
      if (newValue) {
        $scope.sidebar.show();
        $scope.showEdit = false;
        $scope.feature = newValue.feature;

        switch (newValue.options.layer) {
          case 'FieldLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.directive.html';
            break;
          case 'PlotLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.directive.html';
            break;
          default:
            if (newValue.feature.properties.hasOwnProperty('crop_disease')) {
              $scope.sidebar_path =
                '/static/assets/khapps/crowdsource/WheatYellowRustDisease/partials/mapSidebar/wyr_response_data_sidebar.html';
            } else if (
              newValue.feature.properties.hasOwnProperty('soil_sample_slug')
            ) {
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/soil-sample/soil-sample-sidebar.directive.html';
            } else if (
              newValue.feature.properties.hasOwnProperty('store_slug')
            ) {
              $scope.showEdit = true;
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/store/store-sidebar.directive.html';
            } else if (
              newValue.feature.properties.hasOwnProperty('farm_slug')
            ) {
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/farm/farm-sidebar.directive.html';
            } else {
            }
            break;
        }
      }
    });

    //Drawing on Map
    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    $scope.draw_marker = function() {
      add_marker();
    };

    function add_marker() {
      leafletData.getMap(mapid).then(function(map) {
        var marker = new L.Draw.Marker(map, {});
        marker.enable();
      });
    }

    $scope.cancelAddEditFeature = function() {
      $rootScope.post_save_signal();
    };

    $rootScope.post_save_signal = function() {
      $scope.remove_drawn_layer();
      if ($rootScope.selectedFeature) {
        $rootScope.selectedFeature.edited = false;
      }
    };

    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    leafletData.getMap(mapid).then(function(map) {
      leafletData.getLayers().then(function(baselayers) {
        $scope.drawnItems = baselayers.overlays.draw;
        map.on('draw:created', function(e) {
          if (layer) {
            $scope.remove_drawn_layer();
          }
          var type = e.layerType;
          layer = e.layer;
          if (type === 'marker') {
            $rootScope.geocoder = {};
            GenericMapService.getMarkerGeocode($rootScope, layer, function() {
              $rootScope.response = {};
              $rootScope.response['location'] = $rootScope.geocoder.location;

              $mdDialog.show({
                controller: 'WYRResponseRecordController',
                templateUrl:
                  '/static/assets/khapps/crowdsource/WheatYellowRustDisease/partials/forms/wyr-form.html',
                locals: {
                  crop_variety_list: $scope.varieties_choice_list,
                  previous_status_record_date:
                    $scope.previous_status_record_date,
                  growing_season: $rootScope.selected_growing_season.value,
                  member_id: $scope.memberId
                },
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                escapeToClose: true
              });
              $scope.drawnItems.addLayer(layer);
            });
          }
        });
      });
    });

    TeamsOfUserFactory.query(function(result) {
      $scope.team_list = result;
    });

    WheatYellowRustDiseaseConstantsFactory.get(function(data) {
      $scope.varieties_resistance_data = data.varieties;
      $scope.varieties = Object.keys(data.varieties);
      $scope.varieties.shift();
      $scope.planting_season_current = data.current_planting_season;
      $scope.varieties_choice_list = data.varieties_dropdown;
    });

    if (typeof $rootScope.selected_growing_season !== 'undefined') {
      GenericMapService.loadCSResponseData(
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        WheatYellowRustDiseaseFactory
      );
      getCrowdsourceAppStats($scope, WheatYellowRustDiseaseAnalyticsFactory);
    }

    $scope.zoomtolocation = function() {
      angular.extend($rootScope, {
        center: {
          zoom: 18,
          autoDiscover: true
        }
      });
    };

    $scope.zoomtoExtent = function() {
      angular.extend($rootScope, {
        center: {
          lat: 54.5,
          lng: -1.89,
          zoom: 5
        }
      });
    };
  });
